// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/frwFdn91s";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const enabledGestures = {LuZbhmTvn: {hover: true}, UfgFt1vws: {hover: true}};

const cycleOrder = ["UfgFt1vws", "gmKvHhPAa", "LuZbhmTvn"];

const variantClassNames = {gmKvHhPAa: "framer-v-xyd9ui", LuZbhmTvn: "framer-v-wvc3g5", UfgFt1vws: "framer-v-1el4vqg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1.2, stiffness: 300, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Menu - Mega": "gmKvHhPAa", Default: "UfgFt1vws", Light: "LuZbhmTvn"}

const getProps = ({arrow, arrowName, color, height, id, link, tap2, title, width, ...props}) => { return {...props, dMt7E2_tn: color ?? props.dMt7E2_tn ?? "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */", j_KE4sm_E: arrow ?? props.j_KE4sm_E, nJDemTvmM: arrowName ?? props.nJDemTvmM ?? "ChevronRight", tt8lvbGGf: title ?? props.tt8lvbGGf ?? "About", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "UfgFt1vws", x41Bgd582: link ?? props.x41Bgd582, xKRh9Snsp: tap2 ?? props.xKRh9Snsp} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;color?: string;arrow?: boolean;tap2?: any;arrowName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tt8lvbGGf, x41Bgd582, dMt7E2_tn, j_KE4sm_E, xKRh9Snsp, nJDemTvmM, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UfgFt1vws", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapjqltrt = activeVariantCallback(async (...args) => {
if (xKRh9Snsp) {
const res = await xKRh9Snsp(...args);
if (res === false) return false;
}
})

const isDisplayed = () => {
if (baseVariant === "gmKvHhPAa") return false
return true
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WKHpc", sharedStyle.className, classNames)} style={{display: "contents"}}>
{isDisplayed() && (<Transition value={transition}><Link href={x41Bgd582} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-1el4vqg", className)} framer-lv86pm`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"UfgFt1vws"} onTap={onTapjqltrt} ref={ref} style={{...style}} {...addPropertyOverrides({"LuZbhmTvn-hover": {"data-framer-name": undefined}, "UfgFt1vws-hover": {"data-framer-name": undefined}, LuZbhmTvn: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-q8avul"} data-styles-preset={"frwFdn91s"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>About</motion.p></React.Fragment>} className={"framer-17c4we3"} data-framer-name={"About"} layoutDependency={layoutDependency} layoutId={"iGLYOTisi"} style={{"--extracted-r6o4lv": "var(--variable-reference-dMt7E2_tn-JTQIBXbQC)", "--framer-paragraph-spacing": "0px", "--variable-reference-dMt7E2_tn-JTQIBXbQC": dMt7E2_tn}} text={tt8lvbGGf} variants={{"LuZbhmTvn-hover": {"--extracted-r6o4lv": "var(--token-636a3fe4-9f0f-4ae1-a43c-60a743a1245b, rgb(247, 250, 244)) "}, "UfgFt1vws-hover": {"--extracted-r6o4lv": "var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(66, 68, 63))"}}} verticalAlignment={"top"} withExternalLayout/>{j_KE4sm_E && (<motion.div className={"framer-1wdikfh-container"} layoutDependency={layoutDependency} layoutId={"ttsfFD3iK-container"} style={{rotate: 90}} variants={{"UfgFt1vws-hover": {rotate: -90}}}><Material color={dMt7E2_tn} height={"100%"} iconSearch={"Home"} iconSelection={nJDemTvmM} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"ttsfFD3iK"} layoutId={"ttsfFD3iK"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}</motion.a></Link></Transition>)}
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WKHpc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WKHpc .framer-lv86pm { display: block; }", ".framer-WKHpc .framer-1el4vqg { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-WKHpc .framer-17c4we3 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-WKHpc .framer-1wdikfh-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-WKHpc .framer-v-1el4vqg .framer-1el4vqg, .framer-WKHpc .framer-v-wvc3g5 .framer-1el4vqg { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WKHpc .framer-1el4vqg { gap: 0px; } .framer-WKHpc .framer-1el4vqg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WKHpc .framer-1el4vqg > :first-child { margin-left: 0px; } .framer-WKHpc .framer-1el4vqg > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"gmKvHhPAa":{"layout":["auto","auto"]},"LuZbhmTvn":{"layout":["auto","auto"]},"Kzf1Q74O9":{"layout":["auto","auto"]},"VNiuU9w7m":{"layout":["auto","auto"]}}}
 * @framerVariables {"tt8lvbGGf":"title","x41Bgd582":"link","dMt7E2_tn":"color","j_KE4sm_E":"arrow","xKRh9Snsp":"tap2","nJDemTvmM":"arrowName"}
 */
const FramerJTQIBXbQC: React.ComponentType<Props> = withCSS(Component, css, "framer-WKHpc") as typeof Component;
export default FramerJTQIBXbQC;

FramerJTQIBXbQC.displayName = "Header - Menu Item";

FramerJTQIBXbQC.defaultProps = {height: 24, width: 42};

addPropertyControls(FramerJTQIBXbQC, {variant: {options: ["UfgFt1vws", "gmKvHhPAa", "LuZbhmTvn"], optionTitles: ["Default", "Menu - Mega", "Light"], title: "Variant", type: ControlType.Enum}, tt8lvbGGf: {defaultValue: "About", displayTextArea: false, title: "Title", type: ControlType.String}, x41Bgd582: {title: "Link", type: ControlType.Link}, dMt7E2_tn: {defaultValue: "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */", title: "Color", type: ControlType.Color}, j_KE4sm_E: {defaultValue: false, title: "Arrow", type: ControlType.Boolean}, xKRh9Snsp: {title: "Tap 2", type: ControlType.EventHandler}, nJDemTvmM: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "ChevronRight", hidden: undefined, title: "Arrow Name"}} as any)

addFonts(FramerJTQIBXbQC, [...MaterialFonts, ...sharedStyle.fonts])